<template>
  <div class="home page-wrapper">
    <div class="container-xl">
      <!-- Page title -->
      <div class="page-header d-print-none">
        <div class="row align-items-center">
          <div class="col">
            <!-- Page pre-title -->
            <div class="page-pretitle">
              {{ $auth.activeCompany.companyName }}
            </div>
            <h2 class="page-title">Products</h2>
          </div>
          <div class="col-12 col-md-auto ms-auto d-print-none">
            <div class="btn-list">
              <router-link
                :to="`/products/add`"
                class="btn btn-primary d-none d-sm-inline-block"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-plus"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <line x1="12" y1="5" x2="12" y2="19"></line>
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                </svg>

                Add New Product
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-body">
      <div class="container-xl">
        <div class="row">
          <div class="markdown mb-4">
            <p>
              Quickly and easily create pages promoting your remarkable
              products; include the product name, an informative description and
              enticing image. Your products will appear in buyers' searches on
              the business directories your company is listed in.
            </p>
          </div>
          <div v-if="showSaveProductOrder" class="card mb-3">
            <div class="card-status-top bg-info"></div>
            <div class="card-body d-flex align-items-center">
              <strong class="me-auto mb-0 fs-3"
                >Your product display order has been updated.</strong
              >
              <button
                @click.prevent="saveProductOrder"
                :class="[
                  'btn btn-block btn-primary me-3',
                  enabled ? '' : 'disabled',
                ]"
              >
                Save
              </button>
              <a
                href="#"
                @click.prevent="cancelReorder"
                :class="[
                  'btn btn-outline-secondary',
                  enabled ? '' : 'disabled',
                ]"
              >
                Cancel Changes
              </a>
            </div>
          </div>
          <div
            v-if="showReorderSuccessMessage && showSaveProductOrder == false"
            class="alert alert-success alert-dismissible m-0 fs-3 mb-3"
          >
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="alert"
            ></button>
            <strong>Product sort order has been saved successfully.</strong>
          </div>

          <div
            v-else-if="success"
            class="alert alert-success alert-dismissible m-0 fs-3 mb-3"
          >
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="alert"
            ></button>
            <strong>{{ alert }}</strong>
          </div>
          <div
            v-if="deletedSuccess"
            class="alert alert-success alert-dismissible m-0 fs-3 mb-3"
          >
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="alert"
            ></button>
            <strong>Your product has been deleted</strong>
          </div>
          <product
            v-for="productDTO in displayProductDTOs"
            :key="`product-${productDTO.productID}`"
            :productDTO="productDTO"
            :isFeaturedProduct="productDTOs.isFeaturedProduct"
            :showMoveUp="canMoveUp(productDTO)"
            :showMoveDown="canMoveDown(productDTO)"
            @delete="deleteProduct"
            @moveUp="moveUp(productDTO)"
            @moveDown="moveDown(productDTO)"
          ></product>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.header {
  padding: 5px;
  background: #f4f6fa;
  font-size: 0.625rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  line-height: 1.6;
  color: #656d77;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding: 0.5rem 0.5rem;
  border-bottom-width: 1px;
}

.cancel {
  margin-left: 15px;
}
</style>

<script>
import ProductService from "../services/ProductService";
import TrackingService from "../services/TrackingService";

import Product from "../components/Products/Product.vue";

export default {
  name: "product-view",
  components: {
    Product,
  },
  data: () => {
    return {
      enabled: true,
      success: false,
      alert: "",
      deletedSuccess: false,
      productDTOs: [],
      existingFeaturedProducts: [],
      showreordersuccessmsg: false,
      productorderchanged: false,
    };
  },
  methods: {
    updateOrdinalPosition(product, adjustment) {
      let oldOrdinalPosition = product.ordinalPosition;
      let newOrdinalPosition = oldOrdinalPosition + adjustment;

      if (
        0 > newOrdinalPosition ||
        newOrdinalPosition >= this.productDTOs.length
      ) {
        return;
      }

      this.productDTOs
        .filter((p) => p.ordinalPosition == newOrdinalPosition)
        .forEach((p) => (p.ordinalPosition = oldOrdinalPosition));
      product.ordinalPosition = newOrdinalPosition;
      this.productorderchanged = true;
      this.success = false;
      this.deletedSuccess = false;
    },

    canMoveUp(product) {
      return product.ordinalPosition > 0;
    },
    moveUp(product) {
      this.updateOrdinalPosition(product, -1);
    },

    canMoveDown(product) {
      return product.ordinalPosition < this.productDTOs.length - 1;
    },
    moveDown(product) {
      this.updateOrdinalPosition(product, +1);
    },

    async getFeaturedProducts() {
      let company = this.$auth.activeCompany;

      let productSiteFilter = {
        Company: { Companyid: company.companyId },
      };

      // make service call
      let featuredProductsDTO = await ProductService.getFeaturedProducts(
        productSiteFilter
      );

      // save featured products
      this.existingFeaturedProducts.splice();
      this.existingFeaturedProducts.push(...featuredProductsDTO);
    },

    async getProducts() {
      // TODO: Add featured product flag in the getproducts method.
      await this.getFeaturedProducts();

      let company = this.$auth.activeCompany;

      let productFilter = {
        Company: { Companyid: company.companyId },
      };

      // make service call
      let productDTOs = await ProductService.getProducts(productFilter);
      let thumbnailurl = process.env.VUE_APP_CLIENT_THUMBNAIL_URL;

      // iterate through each row (start at 1)
      for (let i = 0; i < productDTOs.length; i++) {
        productDTOs[i].productImageSrc =
          thumbnailurl + productDTOs[i].productImageSrc;
        productDTOs[i].rowID = i + 1;
        productDTOs[i].isFeaturedProduct =
          this.existingFeaturedProducts
            .filter((fp) => fp.isActive)
            .filter((fp) => fp.productID == productDTOs[i].productID).length >
          0;
      }

      // sort by product ordinalposition
      this.productDTOs = productDTOs.sort(
        (a, b) => a.ordinalPosition - b.ordinalPosition
      );

      for (let i = 0; i < this.productDTOs.length; i++) {
        this.productDTOs[i].ordinalPosition = i;
      }
    },

    async saveProductOrder() {
      this.enabled = false;
      let company = this.$auth.activeCompany;
      let ProductSaveProductOrderDTO = {
        company: company,
        products: this.productDTOs,
      };

      try {
        TrackingService.track("update product arrangement");

        let postResult = await ProductService.saveProductOrder(
          ProductSaveProductOrderDTO
        );
        if (postResult.success) {
          this.showreordersuccessmsg = true;
          this.productorderchanged = false;
          await this.getProducts();
        }
      } catch (e) {
        console.log(e);
      }
      this.enabled = true;
    },

    async cancelReorder() {
      this.enabled = false;
      await this.getProducts();
      this.productorderchanged = false;
      this.showreordersuccessmsg = false;
      this.enabled = true;
    },

    async deleteProduct(productid) {
      let company = this.$auth.activeCompany;

      let ProductDeleteDTO = {
        company: company,
        productid: JSON.stringify(productid),
      };

      if (confirm("Are you sure that you want to delete this product?")) {
        try {
          TrackingService.track("delete product");

          let postResult = await ProductService.deleteProduct(ProductDeleteDTO);
          if (postResult.success) {
            this.deletedSuccess = true;
            this.success = false;
            this.showreordersuccessmsg = false;
            this.productorderchanged = false;
            await this.getProducts();
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
  },
  computed: {
    showSaveProductOrder() {
      return this.productorderchanged;
    },
    showReorderSuccessMessage() {
      return this.showreordersuccessmsg;
    },
    displayProductDTOs() {
      return [...this.productDTOs].sort((a, b) => {
        return a.ordinalPosition - b.ordinalPosition;
      });
    },
  },
  async mounted() {
    this.alert = this.$route.params.alert;
    if (this.alert) {
      this.success = true;
    }
    // load product data for company
    await this.getProducts();

    TrackingService.track("viewed products");
  },
};
</script>
