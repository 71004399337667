var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'card',
    'mb-3',
    _vm.productDTO.isFeaturedProduct ? 'border-primary' : '' ]},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row align-items-center justify-content-center"},[_c('div',{staticClass:"col-1 d-none d-lg-block"},[_c('div',{staticClass:"row row-cols-1 text-center"},[_vm._m(0),_c('div',{staticClass:"col mb-3"},[_c('svg',{class:[
                'icon order',
                _vm.showMoveUp ? 'cursor-pointer' : 'cursor-not-allowed',
                _vm.showMoveUp ? '' : 'text-muted' ],attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24","stroke-width":"2","stroke":"currentColor","fill":"none","stroke-linecap":"round","stroke-linejoin":"round"},on:{"click":function($event){return _vm.$emit('moveUp')}}},[_c('path',{attrs:{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}}),_c('path',{attrs:{"d":"M12 9l4 4h-8z"}}),_c('circle',{attrs:{"cx":"12","cy":"12","r":"9"}})])]),_c('div',{staticClass:"col"},[_c('svg',{class:[
                'icon order',
                _vm.showMoveDown ? 'cursor-pointer' : 'cursor-not-allowed',
                _vm.showMoveDown ? '' : 'text-muted' ],attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24","stroke-width":"2","stroke":"currentColor","fill":"none","stroke-linecap":"round","stroke-linejoin":"round"},on:{"click":function($event){return _vm.$emit('moveDown')}}},[_c('path',{attrs:{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}}),_c('circle',{attrs:{"cx":"12","cy":"12","r":"9"}}),_c('path',{attrs:{"d":"M12 15l-4 -4h8z"}})])])])]),_c('div',{staticClass:"col-md-8 text-center text-md-start"},[_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"card-title fs-2 mb-3",attrs:{"title":_vm.productDTO.productUrl,"target":"_blank","to":{
            name: 'Editproducts',
            query: {
              product: _vm.productDTO.productID,
            },
          }}},[_vm._v(" "+_vm._s(_vm.productDTO.productName)+" ")]),_c('div',{staticClass:"d-md-flex col-12"},[(_vm.productDTO.fileName)?_c('a',{staticClass:"me-3 p-1 border",attrs:{"href":_vm.productDTO.productImageUrl,"target":"_blank"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-center h-100 w-100 m-auto"},[_c('img',{staticClass:"card-img-top mh-100 mw-100 img-size",attrs:{"src":_vm.productDTO.productImageSrc,"border":"0","alt":"Title"}})])]):_vm._e(),(_vm.existingProductCategories.length > 0)?_c('ul',{staticClass:"list-unstyled"},[_vm._m(1),_vm._l((_vm.existingProductCategories.filter(
                function (p) { return p.productID == _vm.productDTO.productID; }
              )),function(site,idx){return _c('li',{key:("product-site-" + (site.siteId) + "-" + idx)},[_c('a',{attrs:{"href":("https://" + (site.siteUrl) + "/company/" + (_vm.$auth.activeCompany.companyId) + "/products/" + (_vm.productDTO.productID) + "/"),"title":((_vm.productDTO.productName) + " on the " + (site.siteName)),"target":"_blank"},on:{"click":function($event){return _vm.trackProductLinkClick(site.siteId)}}},[_vm._v(" "+_vm._s(site.siteName)+"->"+_vm._s(site.categoryName)+" "),_c('svg',{staticClass:"icon icon-tabler icon-tabler-external-link",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24","stroke-width":"2","stroke":"currentColor","fill":"none","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('path',{attrs:{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}}),_c('path',{attrs:{"d":"M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5"}}),_c('line',{attrs:{"x1":"10","y1":"14","x2":"20","y2":"4"}}),_c('polyline',{attrs:{"points":"15 4 20 4 20 9"}})])])])})],2):_vm._e()])],1),_c('div',{staticClass:"col-md-2 col-7 text-center"},[_c('router-link',{staticClass:"btn btn-block btn-primary btn-facebook button-size mb-md-3 me-3 me-md-0",attrs:{"to":{
            name: 'Editproducts',
            query: {
              product: _vm.productDTO.productID,
            },
          }}},[_vm._v(" Edit ")]),_c('button',{staticClass:"btn btn-block btn-outline-danger button-size",on:{"click":function($event){return _vm.$emit('delete', _vm.productDTO.productID)}}},[_vm._v(" Delete ")])],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col text-center mb-1"},[_c('strong',{staticClass:"text-muted small"},[_vm._v("ORDER")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('b',[_vm._v("Product can be found under:")])])}]

export { render, staticRenderFns }