<template>
  <div
    :class="[
      'card',
      'mb-3',
      productDTO.isFeaturedProduct ? 'border-primary' : '',
    ]"
  >
    <div class="card-body">
      <div class="row align-items-center justify-content-center">
        <div class="col-1 d-none d-lg-block">
          <div class="row row-cols-1 text-center">
            <div class="col text-center mb-1">
              <strong class="text-muted small">ORDER</strong>
            </div>
            <div class="col mb-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                :class="[
                  'icon order',
                  showMoveUp ? 'cursor-pointer' : 'cursor-not-allowed',
                  showMoveUp ? '' : 'text-muted',
                ]"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                @click="$emit('moveUp')"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M12 9l4 4h-8z"></path>
                <circle cx="12" cy="12" r="9"></circle>
              </svg>
            </div>
            <div class="col">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                :class="[
                  'icon order',
                  showMoveDown ? 'cursor-pointer' : 'cursor-not-allowed',
                  showMoveDown ? '' : 'text-muted',
                ]"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                @click="$emit('moveDown')"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <circle cx="12" cy="12" r="9"></circle>
                <path d="M12 15l-4 -4h8z"></path>
              </svg>
            </div>
          </div>
        </div>
        <div class="col-md-8 text-center text-md-start">
          <router-link
            v-b-tooltip.hover
            :title="productDTO.productUrl"
            target="_blank"
            class="card-title fs-2 mb-3"
            :to="{
              name: 'Editproducts',
              query: {
                product: productDTO.productID,
              },
            }"
          >
            {{ productDTO.productName }}
          </router-link>
          <div class="d-md-flex col-12">
            <a
              :href="productDTO.productImageUrl"
              target="_blank"
              v-if="productDTO.fileName"
              class="me-3 p-1 border"
            >
              <div
                class="d-flex align-items-center justify-content-center h-100 w-100 m-auto"
              >
                <img
                  class="card-img-top mh-100 mw-100 img-size"
                  :src="productDTO.productImageSrc"
                  border="0"
                  alt="Title"
                />
              </div>
            </a>
            <ul
              class="list-unstyled"
              v-if="existingProductCategories.length > 0"
            >
              <li><b>Product can be found under:</b></li>
              <li
                v-for="(site, idx) in existingProductCategories.filter(
                  (p) => p.productID == productDTO.productID
                )"
                v-bind:key="`product-site-${site.siteId}-${idx}`"
              >
                <a
                  :href="`https://${site.siteUrl}/company/${$auth.activeCompany.companyId}/products/${productDTO.productID}/`"
                  :title="`${productDTO.productName} on the ${site.siteName}`"
                  target="_blank"
                  @click="trackProductLinkClick(site.siteId)"
                >
                  {{ site.siteName }}-&gt;{{ site.categoryName }}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-external-link"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5"
                    ></path>
                    <line x1="10" y1="14" x2="20" y2="4"></line>
                    <polyline points="15 4 20 4 20 9"></polyline>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-md-2 col-7 text-center">
          <router-link
            :to="{
              name: 'Editproducts',
              query: {
                product: productDTO.productID,
              },
            }"
            class="btn btn-block btn-primary btn-facebook button-size mb-md-3 me-3 me-md-0"
          >
            Edit
          </router-link>
          <button
            @click="$emit('delete', productDTO.productID)"
            class="btn btn-block btn-outline-danger button-size"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.img-size {
  width: 40%;
}
.button-size {
  height: 40px;
  width: 70px;
}
.order {
  height: 28px;
  width: 28px;
}
@media (min-width: 768px) {
  .button-size {
    height: 50px;
    width: 120px;
  }
  .img-size {
    width: 100%;
  }
}
</style>

<script>
import ProductService from "@/services/ProductService";
import TrackingService from "../../services/TrackingService";

export default {
  name: "products.product",
  props: {
    productDTO: Object,
    showMoveUp: Boolean,
    showMoveDown: Boolean,
  },
  data() {
    return {
      existingProductCategories: [],
    };
  },
  methods: {
    trackProductLinkClick(site) {
      TrackingService.track("open product on site", {
        detail: this.productDTO.productName,
        siteId: site,
      });
    },
    async getProductCategories() {
      let company = this.$auth.activeCompany;

      let productSiteFilter = {
        Company: { Companyid: company.companyId },
      };

      // make service call
      let productCategoryDTOs = await ProductService.getProductSiteCategory(
        productSiteFilter
      );

      // save existing product sites
      this.existingProductCategories = productCategoryDTOs.sort((a) => a.rowID);
    },
  },
  async mounted() {
    this.getProductCategories();
  },
};
</script>
